/* CSS Document */
header {
  height: 55px;
}
.App-header-landing {
    /*display: inline-block; */
}
.nav_containter {
  display: flex;
  justify-content: center;
  z-index: 1;
}
/*Strip the ul of padding and list styling*/
ul {
		list-style-type:none;
		margin:0;
		padding:0;
		position: absolute;
}

/*Create a horizontal list with spacing*/
li {
		display:inline-block;
		float: left;
		/*margin-right: 1px;*/
}

/*Style for menu links*/
li a {
		display:block;
		min-width:140px;
		height: 50px;
		text-align: center;
		line-height: 50px;
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
		color: #FBB040;
		/* background: #2f3036; */
		text-decoration: none;
}

/*Hover state for top level links*/
li:hover a {
		background: #FBB040;
    color: #000;
}

/*Style for dropdown links*/
li:hover ul a {
		background: #f3f3f3;
		color: #2f3036;
		height: 40px;
		line-height: 40px;
}

/*Hover state for dropdown links*/
li:hover ul a:hover {
		background: #FBB040;
		color: #fff;
}

/*Hide dropdown links until they are needed*/
li ul {
		display: none;
}

/*Make dropdown links vertical*/
li ul li {
		display: block;
		float: none;
}

/*Prevent text wrapping*/
li ul li a {
		width: auto;
		min-width: 100px;
		padding: 0 20px;
		text-align:left;
}

/*Display the dropdown on hover*/
ul li a:hover + .hidden, .hidden:hover {
		display: block;
        width: max-content;
}

/*Style 'show menu' label button and hide it by default*/
.show-menu {
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
		text-decoration: none;
		color: #FBB040;
		/* background: #FBB040;*/
		text-align: center;
		padding: 10px 0;
		display: none;
}

/*Hide checkbox*/
input[type=checkbox]{
display: none;
}
/* Show menu when invisible checkbox is checked */
input[type=checkbox]:checked ~ #menu{
display: block;
}
ul#menu {
    /*display: inline-block; */
    z-index: 1;
}

/*Responsive Styles*/

@media screen and (max-width : 760px){
  ul#menu {
      /*display: none;*/
  }
  menu_collapse
  {

  }
	/*Make dropdown links appear inline*/
	ul {
			position: static;
			display: none;
	}
	/*Create vertical spacing*/
	li {
			/* margin-bottom: 2px; */
	}
	/*Make all menu links full width*/
	ul li, li a {
			width: 100%;
	}
	/*Display 'show menu' link*/
	.show-menu {
    display: block;
    float: left;
    padding-top: 0px;
    padding-bottom: 3px;
    border-color: #FBB040;
    border-width: .5px;
    border-style: solid;
    /* padding: 4px; */
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;
    line-height: 25px;
    margin-left: 20px;
    margin-top: 13px;
    margin-bottom: 13px;
	}
	/*Pointer on Hover, so that the user knows of an active link*/
	.show-menu:hover {
		cursor:pointer;
	}
  /*Display the dropdown on hover*/
  ul li a:hover + .hidden, .hidden:hover {
          width: 100%;
  }

  /*Center the text*/
	li ul li a {
		text-align:center;
	}
  li ul li {
      display: block;
      float: left;
  }
  /*Style for menu links*/
  li a {
  		text-align: left;
      padding-left: 20px;

  }
  li:hover ul a {
    /* background: #f3f3f3; */
    /* color: #2f3036; */
    /* height: 40px; */
    line-height: 40px;
    /* width: 100%; */
  }
  .nav_containter {
    display: block;
    justify-content: center;
    z-index: 1;
  }

}
