
hr {
    border: none;
    background: #CFCFCF;
    height: 2px;
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
    width: 100vh;

    opacity: .01;
}
.general-bg{
  background-color: #000;
  /*background-image: url('./images/ghana-362338_960_720.jpg'); */
}
.ddnFooter
{
  position: relative;
  line-height: 24px;
  flex: 1;
  padding: 48px;
  z-index: 0;
  color: #fafafa;
  font-weight: 300;
}

.ddnFooter::after
{

content: "";
padding: 48px;
position: absolute;
z-index: -1;
top: 0;
bottom: 0;
left: 0;
right: 0;
color: #fff;
background:
    url('./images/anansefclogo.svg') top 0 left 0 repeat,
    url('./images/anansefclogo.svg') top 80px left 160px repeat;
opacity: 0.05;
/*background-position-x: 0%;
background-position-y: 0%;
background-size: 320px auto;()*/
background-size: 320px 160px;
}
.ddnFooterDiv
{
width:
}
.App {
  text-align: center;
  /*background-color: #000000;*/

}
.App-logo {
  animation: App-logo-fade 0.5s linear;
  height: 10vmin;
}

.App-logo-small {
  animation: App-logo-fade 0.5s linear;
  position: relative;
    margin-top: -21px;
    top: 12px;
    height: 40px;
  /*height: 3vmin;*/
}
.App-logo-medium {
display: inline-block;
    margin:auto;
padding: 10px;
    height: 11vmin;
  /*height: 3vmin;*/
}
/*.App-logo-spin {
  animation: App-logo-spin infinite 1s linear;
  height: 10vmin;
}*/
.App-title-small {
  animation: App-logo-fade 1s linear;
  width: 15vmin;
}
.App-title-text-small {
  height: 15px;
  margin-bottom:  -1px;
}
.App-title {
  animation: App-logo-fade 1s linear;
  width: 40vmin;
}
.App-title-reg {
  animation: App-logo-fade 0.3s linear;
  width: 40vmin;
}
.App-icon-reg-landing {
  animation: App-logo-fade 0.3s linear;
  width: 19vmin;
}
.App-title-reg-landing {

  animation: App-logo-fade 0.3s linear;
  width: 50vmin;
}
.App-content-landing-container {
/*  width: 100vmin; */
margin-top: 30px;
padding-left: 10px;
padding-right: 10px;
/* background-color: #000000; */
}
.App-content-landing {
/*  width: 100vmin; */

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  max-width: 920px;
  margin: auto;
}

.App-promo {
/*  width: 100vmin; */

padding: 13px 32px;
text-align: right;
}
.App-promo-text {
/*  width: 100vmin; */
max-width: 50%;
letter-spacing: .3px;
  padding: 0px 24px 24px;
text-align: left;
}
.App-header
{
  /* padding: 0.4em;
  text-transform: lowercase;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
   */
   position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    justify-content: flex-start;
padding-left: 10px;
padding: 0;
    height: auto;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
  min-height: 56px;
      font: 500 20px/32px Roboto,"Helvetica Neue",sans-serif;
  /* min-height: 100vh; */
  /*
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  */
  color: white;
}
.App-main {
  background-color: #595959;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
.text-headline {
    font-size: 20px;
    font-weight: 500;
    color: #FBB040;
    margin-top: 10px;
    text-transform: uppercase;
    font-family: 'Humnst777 Blk BT';

}
.text-headline-no-caps {
    font-size: 20px;
    font-weight: 500;
    color: #FBB040;
    margin-top: 10px;
    font-family: 'Humnst777 Blk BT';

}
.web-headline {
    font-size: 20px;
    font-weight: 500;
    color: #FBB040;
    margin-top: 10px;
    text-transform: uppercase;
    font-family: 'Clarendon Blk BT';

}
.ddn-headline {
    font-size: 20px;
    font-weight: 500;
    color: #CFCFCF;
    margin-top: 10px;
    text-transform: uppercase;
    font-family: 'Clarendon Blk BT';

}
p{
display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-family: Roboto;
}
.ddnbuttonContanier
{

text-align: right;
align:center;
 display: inline-block;
 padding-bottom: 12px;
}
.ddn_max_width
{
  /* object-position: 50% 50%; */
  width: 100%;
  /* height: 164px; */
}
.ddn_video_max_width
{
  /* opacity: 1;
  position: absolute;
  min-width: 1842px;
  min-height: 1036px;
  left: 0px;
  top: -86px;
    width: 100%;*/
}
.fullscreen-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

.fullscreen-bg__video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.ddnbutton
{

  display: flex;
justify-content: center;
/*width: 184px;*/
height: 40px;
padding: 0 24px;
font-size: 18px;
font-weight: 600;
line-height: 40px;
background-color: #FFF;
color: #595959;
border-radius: 48px;
box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
box-sizing: border-box;
cursor: pointer;
margin-bottom: -11px;
text-decoration: none;
text-transform: uppercase;
    overflow: hidden;
    border: none;
    font-family: 'Roboto', sans-serif;

}
.ddnbutton:hover {
      opacity: .9;
  color: #6e6e6e;

}
.ddnTextHeadline
{
  font-family: 'serif';
  font-size: 12.2vmin;
  font-weight: 500;
  margin: 0px 0px 24px 16px;
  color: #FBB040
}
.ddnTextSlogan
{
  font-family: 'Clarendon Blk BT';
  font-size: 5.2vmin;
  font-weight: 500;
  margin: 0px 0px 24px 16px;
  color: #CCCCCD
}
.ddnPower
{
  display:inline;
  font-family: 'Clarendon Blk BT';
  font-size: 17px;

  font-weight: 500;

  color: #CCCCCD
}
.ddnPowerLogo
{
  width:79px;
  margin-bottom: -3px;
  color: #000;
}

@keyframes App-logo-shrink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes App-logo-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-color-scheme: dark) {
  .App-content-landing-container {
  /*  width: 100vmin; */
  margin-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
  /* background-color: #000000; */
  }
}
@media (max-width: 1100px)
{
  hr {
      border: none;
      background: #CFCFCF;
      height: 2px;
      display: block;
      unicode-bidi: isolate;
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
      margin-inline-start: auto;
      margin-inline-end: auto;
      overflow: hidden;
      width: auto;
      opacity: .01;

  }
  .App-content-landing-container {
  /*  width: 100vmin; */
  margin-top: 0px;

  /* background-color: #000000; */
  }
}
@media (prefers-color-scheme: dark && 1100px) {
  .App-content-landing-container {
  /*  width: 100vmin; */
  margin-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
  /* background-color: #000000; */
  }
  .App {
    text-align: center;
    background-color: #595959;

  }
}
@media (max-width: 599px)
{
  hr {
      border: none;
      background: #CFCFCF;
      height: 2px;
      display: block;
      unicode-bidi: isolate;
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
      margin-inline-start: auto;
      margin-inline-end: auto;
      overflow: hidden;
    width: auto;
    opacity: .01;


  }
.App-logo-small  {
    min-height: 40px;
    animation: App-logo-fade 0.5s linear;
    position: relative;
      margin-top: -21px;
      top: 12px;
      height: 40px;
}
.App-title-small {
  animation: App-logo-fade 1s linear;
  width: 15vmin;
  display: none;
}

.App-header
{
   position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    justify-content: flex-start;
padding-left: 10px;
padding: 0;
    height: auto;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
 /* background-color: #000000; */
  min-height: 56px;
      font: 500 20px/32px Roboto,"Helvetica Neue",sans-serif;

  color: white;
}

.App-header-landing
{
   /*position: fixed; */
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    justify-content: flex-start;
    padding-left: 10px;
    padding: 0;
    height: auto;
    overflow: hidden;
    /*display: flex;*/
    box-sizing: border-box;
    width: 100%;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    min-height: 56px;
    font: 500 20px/32px Roboto,"Helvetica Neue",sans-serif;

  color: white;
}
.App-main {
  background-color: #595959;
    margin-top: 56px;
      max-height: 486px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-promo {
/*  width: 100vmin; */
order: 1;
padding: 16px 0px 0px 0px;
width: 90%;
}
.App-promo2 {
/*  does multiple images */
order: 1;
width: 100%;
padding-bottom: 0px;
margin-top: 0px;
margin-bottom: 0px;
}
.App-promo-text {
  order: 2;
/*  width: 100vmin; */
letter-spacing: .3px;
max-width: 100%;
padding: 10px 16px 16px 16px;
margin-top: 0px;
margin-bottom: 0px;

}
.text-headline {
  text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #FBB040;
    margin-top: 10px;
    text-transform: uppercase;
    font-family: 'Humnst777 Blk BT';

}
p{
text-align:justify;
}
.App-content-landing {
/*  width: 100vmin; */
/* background-color: #000000; */
  color: white;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  box-sizing: border-box;


}
.App-title-reg-landing {
  animation: App-logo-fade 0.3s linear;
  width: 100%;
}
.App-content-landing-container {
/*  width: 100vmin; */
margin-top: 0px;

/* background-color: #000000; */
}
.App-logo-medium {
display: inline-block;
    margin:auto;
padding: 12px;
    height: 24vmin;
  /*height: 3vmin;*/
}
}

@media (max-width: 699px) and (orientation:landscape)
{
  .App-content-landing-container {
  padding-top: 20px;


  }
  .text-headline {
      font-size: 19px;
  }
}
@font-face {
    font-family: 'Clarendon Blk BT';
    font-weight: 900;
    font-style: normal;
    src: url('./fonts/ClarendonBT-Black.eot');
    src: url('./fonts/ClarendonBT-Black.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ClarendonBT-Black.woff2') format('woff2'),
        url('./fonts/ClarendonBT-Black.woff') format('woff'),
        url('./fonts/Clrndnk.ttf') format('truetype'), /* Safari, Android, iOS */
        url('./fonts/ClarendonBT-Black.svg#ClarendonBT-Black') format('svg');

}
@font-face {
    font-family: 'Humnst777 Blk BT';
    src: url('./fonts/Humanist777BT-BlackB.eot');
    src: url('./fonts/Humanist777BT-BlackB.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Humanist777BT-BlackB.woff2') format('woff2'),
        url('./fonts/Humanist777BT-BlackB.woff') format('woff'),
        url('./fonts/Humanist777BT-BlackB.ttf') format('truetype'),
        url('./fonts/Humanist777BT-BlackB.svg#Humanist777BT-BlackB') format('svg');
    font-weight: 900;
    font-style: normal;
}
